/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    a: "a",
    strong: "strong",
    ul: "ul",
    li: "li",
    h2: "h2",
    div: "div"
  }, _provideComponents(), props.components), {TableOfContents, VideoIframe, LandingPageCta, ThreeColCta} = _components;
  if (!LandingPageCta) _missingMdxReference("LandingPageCta", true);
  if (!TableOfContents) _missingMdxReference("TableOfContents", true);
  if (!ThreeColCta) _missingMdxReference("ThreeColCta", true);
  if (!VideoIframe) _missingMdxReference("VideoIframe", true);
  return React.createElement(React.Fragment, null, React.createElement(TableOfContents, {
    tableOfContents: props.data.mdx.tableOfContents
  }), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.a, {
    href: "/hearing-aids/",
    className: "c-md-a"
  }, "High-quality hearing aids"), " are expensive, precision instruments which need to be taken care of properly. Poor cleaning and maintenance of your hearing aid can reduce its life span significantly and generally impair its level of performance, while on a purely hygienic level, no-one wants a dirty hearing aid near their inner ear."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "So it’s important to learn how to maintain and clean your hearing aid. That should be a non-brainer, but it’s surprisingly common to find people who let their devices fall into disrepair. Don’t let that happen to you, and use this guide to keep your auditory equipment in great shape for as long as possible."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "Follow these steps to clean your hearing aid:")), "\n", React.createElement(_components.ul, {
    className: "c-md-list"
  }, "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Wash your hands. Always ensure you take out the battery before starting a cleaning process."), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Clean the microphone with a brush and a wax pick to remove any ear wax that has gathered on the surface of the microphone."), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Clean the hearing aid casing by focusing on any openings in the hearing aid casing, and gently brush off any dirt deposits"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Clean the earmold: remove it from the device and soak it in warm, soapy water. Wipe it down with hot water every night as well – and leave them to dry thoroughly before wearing the hearing aid."), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Finally, put the hearing aid into a drying jar to let it dry."), "\n"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, " "), "\n", React.createElement(VideoIframe, {
    title: "Hear.com Tips and Tricks | How to Clean Your Hearing Aids",
    width: "560",
    height: "315",
    src: "https://www.youtube.com/embed/EbkFgU0igyk?si=DJExGCOhhrotWIjP"
  }), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "things-to-watch-out-for-when-cleaning-your-hearing-aid",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#things-to-watch-out-for-when-cleaning-your-hearing-aid",
    "aria-label": "things to watch out for when cleaning your hearing aid permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Things to watch out for when cleaning your hearing aid"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Even if you take all of these precautions, you’ll still need to set aside some time to physically clean your hearing aid. Make sure you do so, as many hearing aid users neglect to do so, causing extra costs and poor performance in the long run."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Be careful when cleaning your hearing aid, though, as one false move can damage the fragile parts."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Before you start, be sure to wash your hands and your fingers in particular. There’s no sense in washing your hearing aid, only to dirty it again with grease or dirt from your body."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "It’s also worth getting hold of some ", React.createElement(_components.a, {
    href: "/hearing-aids/accessories",
    className: "c-md-a"
  }, "useful accessories"), ". A brush and wax pick are advisable, although tooth picks or cocktail sticks might be used as well. Use them ", React.createElement(_components.a, {
    href: "/resources/hearing-aid-success/earwax/",
    className: "c-md-a"
  }, "to remove any ear wax"), " that has gathered on the surface of the microphone. If you don’t, it can cause some nasty feedback issues."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "When it comes to removing dirt, avoid chemical or alcohol solutions where possible. Whether you are cleaning ", React.createElement(_components.a, {
    href: "/hearing-aids/types/bte/",
    className: "c-md-a"
  }, "a behind-the-ear"), " or ", React.createElement(_components.a, {
    href: "/hearing-aids/types/ite/",
    className: "c-md-a"
  }, "inside-the-ear device"), ", focus on any openings in the hearing aid casing, and gently brush off any dirt deposits. You can use a soft-bristled toothbrush if you like, although specialist cleaning brushes are also available."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "If you are using a BTE device, you’ll also need to keep the earmold in good condition. To do so, remove it from the device and soak it in warm, soapy water at least once a week. Wipe it down with hot water every night as well – and leave them to dry thoroughly before wearing the hearing aid."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "With both varieties of hearing aid, it’s also a good idea to remove the ", React.createElement(_components.a, {
    href: "/hearing-aids/batteries",
    className: "c-md-a"
  }, "batteries"), " overnight and to brush the battery compartment as well. It’s something that many wearers overlook."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "how-often-do-you-need-to-clean-your-hearing-aid",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#how-often-do-you-need-to-clean-your-hearing-aid",
    "aria-label": "how often do you need to clean your hearing aid permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "How often do you need to clean your hearing aid?"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Cleaning your hearing aid isn’t a difficult process, but it is time consuming. Thankfully, you probably don’t need to carry out a thorough cleaning every night. Instead, just wipe your device down and soak the earmold, but it’s not necessary to get inside the hearing aid."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Deeper cleaning should take place once a week at a minimum. As a general rule, ", React.createElement(_components.strong, null, "the more often you clean your hearing aid the longer it will last"), ", but once a week cleaning should prolong its lifetime considerably. Any less, and dirt and wax can become encrusted, and components can start to become damaged."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "how-to-look-after-your-hearing-aid-when-carrying-it-around-town",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#how-to-look-after-your-hearing-aid-when-carrying-it-around-town",
    "aria-label": "how to look after your hearing aid when carrying it around town permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "How to look after your hearing aid when carrying it around town"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "One of the most common mistakes that hearing aid users make is when their device sustains damage or becomes dirty when being carried around town. Naturally, hearing aids will sustain a bit of wear and tear just from the effects of everyday life, but there are things you can do to lessen the damage."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "The first thing to remember is that when your hearing aid isn’t needed, make sure that you carry it in a separate case. Most ", React.createElement(_components.a, {
    href: "/hearing-aids/brands",
    className: "c-md-a"
  }, "hearing aid manufacturers"), " supply cases with their devices which have spaces for all of the various components and interiors that won’t accumulate dust. Never just drop your device into a handbag or backpack and hope that it won’t become dirty or scuffed."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Secondly, take account of the weather. Hearing aids shouldn’t be worn when the ambient temperature and humidity levels are high (just like you shouldn’t leave them lying around in bathrooms). Try not to wear them in the rain, either, and avoid exposing them too much to intense, direct sunlight as UV rays can be damaging."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "When the weather is sunny, take extra care about applying sunblock or moisturizer. Be sure to rub it in thoroughly, so that as little residue as possible remains on the skin. And don’t apply sunblock after you have inserted your hearing aid. Do so before to avoid getting anything on the microphone."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "If you do get caught out in the rain (and it’s going to happen), be sure that you have a way to keep your hearing aid dry. Hooded jackets are better than umbrellas, which can still let water blow in from the side. Rain water can corrode your device’s delicate components, so try to shield it wherever you can."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Finally, when you get in after a day at work or out on the town, allow your hearing aid to “breathe”. Open the battery compartment and allow air to flow through. It helps to dislodge dust particles that may have accumulated during the day."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "keeping-your-hearing-aid-clean-should-be-a-top-priority",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#keeping-your-hearing-aid-clean-should-be-a-top-priority",
    "aria-label": "keeping your hearing aid clean should be a top priority permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Keeping your hearing aid clean should be a top priority"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Hearing aids are delicate devices. Over a period of weeks or months, they inevitably become dusty and dirty, both from contact with your body and the outside world. So, they need some TLC."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.a, {
    href: "/hearing-aids/prices/",
    className: "c-md-a"
  }, "When you pay hundreds of dollars for a life-changing hearing device"), ", you shouldn’t waste that money or damage your equipment through neglect. A few moments every night to carry out basic cleaning, and a longer cleaning session every week, ensures your device lasts for years."), "\n", React.createElement(LandingPageCta, {
    copy: "START NO-RISK TRIAL",
    classes: "c-cta--content"
  }), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "resources",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#resources",
    "aria-label": "resources permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Resources"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Learn everything you need to know about hearing aids and hearing loss."), "\n", React.createElement(ThreeColCta, {
    ImgSrc1: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/women-with-tablet.jpg",
    ContentTitle1: "Hearing Test",
    ContentCopy1: "Check how is your hearing in less than 5 minutes.",
    Url1: "/hearing-test/",
    ImgSrc2: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/hearing-aids-behind-ear-blur.jpg",
    ContentTitle2: "Value & Price",
    ContentCopy2: "Are hearing aids worth the cost? We asked an expert.",
    Url2: "/hearing-aids/prices/",
    ImgSrc3: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/go-ax-grey-blur.jpg",
    ContentTitle3: "Hearing Aids",
    ContentCopy3: "Connect you to the world around you with our wide range of devices.",
    Url3: "/resources/hearing-aids/"
  }));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
